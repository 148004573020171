import React from "react";
import { Modal, IconButton, mergeStyleSets, getTheme, FontWeights, IIconProps, TextField } from "@fluentui/react";
import { List } from '@fluentui/react/lib/List';
import { DefaultButton, PrimaryButton, IButtonStyles } from '@fluentui/react/lib/Button';
import {
    IHatchetAiCompanyProjectTrainModel,
    IRecentModel
} from "../../../../models/applicationState";
import { Stack, IStackProps, IStackStyles } from '@fluentui/react/lib/Stack';
import { ITheme, getFocusStyle } from '@fluentui/react/lib/Styling';
import { Icon } from '@fluentui/react/lib/Icon';
import axios from "axios";
import {
    getCookieEmail,
    parseEndpointByType,
} from "../../common/jsonSchemaFormHelper";


export interface ITrainModelListModalProps {
    showTrainModelList: boolean;
    closeTrainModelListModal: () => void;
    addTrainModelFromProject: (modelId: string, model: IRecentModel) => void;
    projectName: string;
}

export interface ITrainModelListModalState {
    availableModels: IHatchetAiCompanyProjectTrainModel[];
    searchText: string;
}

export default class TrainModelListModal extends React.Component<ITrainModelListModalProps, ITrainModelListModalState> {

    theme = getTheme();
    contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
        },
        header: [
            this.theme.fonts.xLargePlus,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${this.theme.palette.themePrimary}`,
                color: this.theme.palette.neutralPrimary,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 14px 24px',
            },
        ],
        heading: {
            color: this.theme.palette.neutralPrimary,
            fontWeight: FontWeights.semibold,
            fontSize: 'inherit',
            margin: '0',
        },
        body: {
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
    });
    iconButtonStyles: Partial<IButtonStyles> = {
        root: {
            color: this.theme.palette.neutralPrimary,
            marginLeft: 'auto',
            marginTop: '4px',
            marginRight: '2px',
        },
        rootHovered: {
            color: this.theme.palette.neutralDark,
        },
    };
    columnProps: Partial<IStackProps> = {
        tokens: { childrenGap: 15 },
        styles: { root: { width: 550 } },
    };
    stackStyles: Partial<IStackStyles> = { root: { width: 550 } };

    constructor(props) {
        super(props);
        this.state = {
            availableModels: [],
            searchText: ''
        };
        this.onSearchTextChanged = this.onSearchTextChanged.bind(this);
        this.getHatchetAiCompanyProjectTrainModels = this.getHatchetAiCompanyProjectTrainModels.bind(this);
    }

    public render() {
        return (
            <Modal isOpen={this.props.showTrainModelList}>
                <div className={this.contentStyles.header}>
                    <h2 className={this.contentStyles.heading} >
                        Model Management
                    </h2>
                    <IconButton
                        styles={this.iconButtonStyles}
                        iconProps={{ iconName: 'Cancel' }}
                        ariaLabel="Close popup modal"
                        onClick={this.props.closeTrainModelListModal}
                    />
                </div>
                <div className={this.contentStyles.body}>
                    <Stack tokens={{ childrenGap: 50 }} styles={this.stackStyles}>
                        <Stack horizontal horizontalAlign="start" verticalAlign="end" tokens={{ childrenGap: 5 }} >
                            <Stack.Item grow={4} align="stretch">
                                <TextField
                                    label={'Search'}
                                    value={this.state.searchText}
                                    onChange={this.onSearchTextChanged}
                                />
                            </Stack.Item>
                            <Stack.Item>
                                <IconButton
                                    styles={this.iconButtonStyles}
                                    iconProps={{ iconName: 'Search' }}
                                    ariaLabel="Search"
                                    onClick={this.getHatchetAiCompanyProjectTrainModels}
                                />
                            </Stack.Item>
                        </Stack>
                        <Stack {...this.columnProps}>
                            <List items={this.state.availableModels} onRenderCell={this.onRenderCell} />
                        </Stack>
                        <Stack.Item align="end">
                            <DefaultButton text="Close" onClick={this.props.closeTrainModelListModal} />
                            {/*<PrimaryButton text="Upload" onClick={this.onSaveEndpoint} />*/}
                        </Stack.Item>
                    </Stack>
                </div>
            </Modal>
        )
    }

    public getHatchetAiCompanyProjectTrainModels = () => {
        axios.post(
            parseEndpointByType("GetProjectTrainModels"),
            {
                email: getCookieEmail(),
                searchText: this.state.searchText
            }
        ).then(res => {
            if (res && res.data && res.data.successful) {
                const modelList = res.data.hatchetAiCompanyProjectTrainModelList as IHatchetAiCompanyProjectTrainModel[];
                this.setState({
                    availableModels: modelList
                });
            }
        });
    }

    private onSearchTextChanged = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        this.setState({
            searchText: newValue
        });
    }

    private round(value: number, decimals: number) {
        var tempValue = `${value}e${decimals}`;
        return Number(Math.round(parseFloat(tempValue)) + 'e-' + decimals.toString());
    }
       

    private onRenderCell = (item: IHatchetAiCompanyProjectTrainModel, index: number | undefined): JSX.Element => {
        let average = 0;
        if (item.accuracy) {
            average = this.round(item.accuracy * 100, 2);
        }        
        const modelJson = JSON.parse(item.modelJson) as IRecentModel;
        let owner = false;
        if (item.uploadedBy === getCookieEmail() && item.projectName === this.props.projectName) {
            owner = true;
        }
        let scope = 'Private';
        if (item.scopeType === 1) {
            scope = 'Company Wide';
        }
        if (item.scopeType === 2) {
            scope = 'Crowd Source';
        }
        return (
            <div className={this.classNames.itemCell} data-is-focusable={true}>
                <div className={this.classNames.itemContent}>
                    <div className={this.classNames.itemName}>{item.modelId}</div>
                    <div className={this.classNames.itemIndex}>Average: {average}</div>
                    <div>{`Project Name: ${item.projectName}`}</div>
                    <div>{`Company: ${item.companyName}`}</div>
                    <div>{`Uploaded by: ${item.uploadedBy}`}</div>
                    <div className={this.classNames.itemIndex}>{`Scope: ${scope}`}</div>
                    <div>
                        {/*{this.props.predictModelId !== item.modelInfo.modelId &&*/}
                        {/*    <DefaultButton text="Set Default" onClick={() => this.props.setAsDefault(item.modelInfo.modelId)} />*/}
                        {/*}*/}
                        {!owner &&
                            <PrimaryButton text="Download" onClick={() => this.props.addTrainModelFromProject(item.modelId, modelJson)} style={{ marginTop: '15px' }} />
                        }                        
                    </div>
                </div>                
            </div>
        );
    };

    private classNames = mergeStyleSets({
        itemCell: [
            getFocusStyle(this.theme, { inset: -1 }),
            {
                minHeight: 54,
                padding: 10,
                boxSizing: 'border-box',
                borderBottom: `1px solid ${this.theme.semanticColors.bodyDivider}`,
                display: 'flex',
                selectors: {
                    '&:hover': { background: this.theme.palette.neutralLight },
                },
            },
        ],
        itemImage: {
            flexShrink: 0,
        },
        itemContent: {
            marginLeft: 10,
            overflow: 'hidden',
            flexGrow: 1,
        },
        itemName: [
            this.theme.fonts.xLarge,
            {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            },
        ],
        itemIndex: {
            fontSize: this.theme.fonts.small.fontSize,
            color: this.theme.palette.neutralTertiary,
            marginBottom: 10,
        },
        chevron: {
            alignSelf: 'center',
            marginLeft: 10,
            color: this.theme.palette.neutralTertiary,
            fontSize: this.theme.fonts.large.fontSize,
            flexShrink: 0,
        },
    });
}
