import React from "react";
import { Modal, IconButton, mergeStyleSets, getTheme, FontWeights, IIconProps, TextField } from "@fluentui/react";
import { DefaultButton, PrimaryButton, IButtonStyles } from '@fluentui/react/lib/Button';
import { Stack, IStackProps, IStackStyles } from '@fluentui/react/lib/Stack';
import { Dropdown, DropdownMenuItemType, IDropdownOption, IDropdownStyles } from '@fluentui/react/lib/Dropdown';
import {
    IHatchetAiCompanyAPIConnection
} from "../../../../models/applicationState";

import BasicAuth from './basicAuth';
import { update } from "../../../../redux/actions/prebuiltSettingsActions";

export interface INewEndpointModalProps {
    showEditModal: boolean,
    closeUploadModal: () => void,
    updateEndpoint: (endpoint: IHatchetAiCompanyAPIConnection) => void,
    model?: IHatchetAiCompanyAPIConnection,
    updateEditFlag: number
}

export interface INewEndpointModalState {
    selectedAuthenticationType: IDropdownOption,
    model: IHatchetAiCompanyAPIConnection,
    updateEditFlag: number
}

export default class NewEndpointModal extends React.Component<INewEndpointModalProps, INewEndpointModalState> {

    theme = getTheme();
    contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
        },
        header: [
            this.theme.fonts.xLargePlus,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${this.theme.palette.themePrimary}`,
                color: this.theme.palette.neutralPrimary,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 14px 24px',
            },
        ],
        heading: {
            color: this.theme.palette.neutralPrimary,
            fontWeight: FontWeights.semibold,
            fontSize: 'inherit',
            margin: '0',
        },
        body: {
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
    });
    iconButtonStyles: Partial<IButtonStyles> = {
        root: {
            color: this.theme.palette.neutralPrimary,
            marginLeft: 'auto',
            marginTop: '4px',
            marginRight: '2px',
        },
        rootHovered: {
            color: this.theme.palette.neutralDark,
        },
    };
    cancelIcon: IIconProps = { iconName: 'Cancel' };
    stackStyles: Partial<IStackStyles> = { root: { width: 550 } };
    columnProps: Partial<IStackProps> = {
        tokens: { childrenGap: 15 },
        styles: { root: { width: 550 } },
    };
    authenticationOptions = [
        { key: 1, text: 'Basic Authentication' },
        { key: 2, text: 'Query-string API Key' },
        { key: 3, text: 'Html Header API Key' },
        { key: 4, text: 'OAuth 2.0' }
    ];
    basicAuthRef = null;
    
    constructor(props) {
        super(props);
        this.state = {
            selectedAuthenticationType: null,
            model: null,
            updateEditFlag: 0
        }
        this.onAuthenticationTypeChange = this.onAuthenticationTypeChange.bind(this);
        this.onNameChange = this.onNameChange.bind(this);
        this.onEndpointUrlChange = this.onEndpointUrlChange.bind(this);
        this.onSaveEndpoint = this.onSaveEndpoint.bind(this);
    }

    componentDidUpdate() {
        if (this.props.updateEditFlag > 0) {
            if (this.props.updateEditFlag !== this.state.updateEditFlag) {
                this.setState({
                    model: this.props.model,
                    updateEditFlag: this.props.updateEditFlag
                });
            }
        }
    }

    onAuthenticationTypeChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
        let { model } = this.state;
        model.authType = item.key as number;
        this.setState({
            model
        });
    };

    onSaveEndpoint(): void {
        const { model } = this.state;
        let updateModel: IHatchetAiCompanyAPIConnection = {
            id: model.id,
            name: model.name,
            method: model.method,
            endpointUrl: model.endpointUrl,
            authType: model.authType,
            authEndpointUrl: model.authEndpointUrl,
            credentialsJson: this.basicAuthRef.getCredentialsJson(),
            headersJson: '',
            bodyText: '',
            bodyTextType: '',
            bodyType: '',
            isPublic: false,
            fileUploadEndpoint: ''
        };
    }

    onNameChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
        let { model } = this.state;
        model.name = newValue;
        this.setState({
            model
        });
    }

    onEndpointUrlChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
        let { model } = this.state;
        model.endpointUrl = newValue;
        this.setState({
            model
        });
    }

    public render() {
        let { selectedAuthenticationType, model } = this.state;
        if (!model) {
            model = {
                id: 0,
                name: '',
                method: 'POST',
                endpointUrl: '',
                authType: 0,
                authEndpointUrl: '',
                credentialsJson: '',
                headersJson: '',
                bodyText: '',
                bodyTextType: '',
                bodyType: '',
                isPublic: false,
                fileUploadEndpoint: ''
            }
        }
        return (
            <Modal
                isOpen={this.props.showEditModal}
            >
                <div className={this.contentStyles.header}>
                    <h2 className={this.contentStyles.heading} >
                        {model.id < 1 ? 'New Endpoint' : 'Edit Endpoint'}
                    </h2>
                    <IconButton
                        styles={this.iconButtonStyles}
                        iconProps={{ iconName: 'Cancel' }}
                        ariaLabel="Close popup modal"
                        onClick={this.props.closeUploadModal}
                    />
                </div>
                <div className={this.contentStyles.body}>
                    <Stack tokens={{ childrenGap: 50 }} styles={this.stackStyles}>
                        <Stack {...this.columnProps}>
                            <TextField label="Name" required value={model.name} onChange={this.onNameChange} />
                            <TextField label="Method" required value={model.method} readOnly />
                            <TextField label="Endpoint URL" required value={model.endpointUrl} onChange={this.onEndpointUrlChange} />
                            <Dropdown
                                label="Authentication Type"
                                selectedKey={model.authType}
                                onChange={this.onAuthenticationTypeChange}
                                placeholder="Select an option"
                                options={this.authenticationOptions}
                                styles={{ dropdown: { width: 550 } }}
                            />
                            {model && model.authType && model.authType == 1 &&
                                <BasicAuth
                                credentialsJson={model.credentialsJson}
                                ref={(el) => (this.basicAuthRef = el)}
                                />
                            }
                        </Stack>
                        <Stack.Item align="end">
                            <DefaultButton text="Close" onClick={this.props.closeUploadModal} />
                            <PrimaryButton text="Upload" onClick={this.onSaveEndpoint} />
                        </Stack.Item>
                    </Stack>
                </div>
            </Modal>
        )
    }
}
