import * as React from "react";
import {
    ICustomizations,
    Customizer,
    ContextualMenu,
    IDragOptions,
    Modal,
    IconButton,
} from "@fluentui/react";
import { getDarkGreyTheme } from "../../../../common/themes";
import "./tableView.scss";
import { ModalBody } from "reactstrap";

interface ITableViewProps {
    handleTableViewClose: () => any;
    tableToView: object;
}

export const TableView: React.FunctionComponent<ITableViewProps> = (props) => {
    const dark: ICustomizations = {
        settings: {
            theme: getDarkGreyTheme(),
        },
        scopedSettings: {},
    };

    const dragOptions: IDragOptions = {
        moveMenuItemText: "Move",
        closeMenuItemText: "Close",
        menu: ContextualMenu,
    };

    function getTableBody() {
        const table = props.tableToView;

        let tableBody = null;
        if (table !== null) {
            tableBody = [];
            const rows = table["rows"];
            // const columns = table["columns"];
            for (let i = 0; i < table["rowCount"]; i++) {
                const tableRow = [];
                tableBody.push(<tr key={i}>{tableRow}</tr>);
            }
            table["cells"].forEach((cell) => {
                const rowIndex = cell["rowIndex"];
                const columnIndex = cell["columnIndex"];
                const rowSpan = cell["rowSpan"];
                const colSpan = cell["columnSpan"];

                const children = tableBody[rowIndex]["props"]["children"];
                
                children[columnIndex] = (
                    <td key={columnIndex} colSpan={colSpan} rowSpan={rowSpan}>
                        {cell["content"]}
                    </td>
                );
            });
        }
        return tableBody;
    }

    const copyTableClick = () => {
        const urlField = document.querySelector(id());
        const el = document.createElement("textarea");
        const arrayText = urlField.outerHTML;
        el.value = arrayText;

        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
        alert("Table Copied!");
    };

    const copyTextClick = () => {
        const urlField = document.querySelector(id());
        const el = document.createElement("textarea");
        const arrayText = urlField.textContent;
        el.value = arrayText;
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
        alert("Text Copied!");
    };

    const id = (fullId: boolean = true) => {
        return (
            (fullId ? "#" : "") +
            "table_" +
            (props.tableToView as any).boundingRegions[0].polygon[0]
                .toString()
                .replace(".", "")
        );
    };

    const onDismiss = () => {
        props.handleTableViewClose();
    };

    return (
        <Customizer {...dark}>
            <Modal
                titleAriaId={"Table view"}
                isOpen={props.tableToView !== null}
                isModeless={false}
                isDarkOverlay={true}
                dragOptions={dragOptions}
                onDismiss={onDismiss}
                scrollableContentClassName={"table-view-scrollable-content"}
                className="modal-table"
            >
                <div className="text-right">
                    <IconButton
                        iconProps={{ iconName: "copy" }}
                        title="Copy text and table for excel"
                        onClick={copyTableClick}
                    ></IconButton>
                    <IconButton
                        iconProps={{ iconName: "CopyText" }}
                        title="Copy text"
                        onClick={copyTextClick}
                    ></IconButton>
                    <IconButton
                        iconProps={{ iconName: "Cancel" }}
                        onClick={props.handleTableViewClose}
                    />
                </div>
                <ModalBody>
                    <div
                        id="table-view-container"
                        className="table-view-container"
                    >
                        <table className="viewed-table" id={id(false)}>
                            <tbody>{getTableBody()}</tbody>
                        </table>
                    </div>
                </ModalBody>
            </Modal>
        </Customizer>
    );
};
