
import axios from 'axios';
import { IAdHocProject, IAdHocProjectResult, IHatchetAiCompanyProjectOcr, IProject } from "../models/applicationState";
import {
    getCookieEmail,
    parseEndpointByType,
} from "../react/components/common/jsonSchemaFormHelper";


export interface IAdHocOcrService {
    load(projectKey: string): Promise<IAdHocProjectResult>;
}

export default class AdHocOcrService implements IAdHocOcrService {
    public async load(projectKey: string) {
        return new Promise<IAdHocProjectResult>(async (resolve, reject) => {
            try {
                const project = await axios.get<IAdHocProjectResult>(parseEndpointByType(`getadhocproject/${projectKey}`));
                if (project) {
                    resolve(project.data);
                }                
            }
            catch(ex) {
                reject(ex);
            }
        })        
    }

    public async getOcrForPage(projectKey: string, page: number, sourceFile: string, assetName: string) {
        return new Promise<IHatchetAiCompanyProjectOcr>(async (resolve, reject) => {
            try {
                const ocr = await axios.post(parseEndpointByType('GetOcrForPage'), 
                    {
                        key: projectKey,
                        pageNumber: page,
                        sourceFile: sourceFile, 
                        sourceFilename: assetName
                    });
                if (ocr && ocr.data && ocr.data.successful) {
                    resolve(ocr.data.hatchetAiCompanyProjectOcr);
                }                
            }
            catch(ex) {
                reject(ex);
            }
        })
    }

    public async updateProjectAssetLabel(projectId: string, projectName: string, assetName: string, labelData: string, updateLabelInContainer: boolean, label: string) {
        return new Promise(async (resolve, reject) => {
            try {
                const result = await axios.post(parseEndpointByType('UpdateProjectAssetLabel'), 
                    {
                        projectId: projectId,
                        projectName: projectName,
                        assetName: assetName,
                        labelData: labelData, 
                        updateLabelInContainer: updateLabelInContainer, 
                        label: label
                    });
                if (result && result.data && result.data.successful) {
                    resolve(result.data.successful);
                }                
            }
            catch(ex) {
                reject(ex);
            }
        })
    }

    public async exportProjectLabelToSource(projectId: string, projectName: string, assetName: string) {
        return new Promise(async (resolve, reject) => {
            try {
                const result = await axios.post(parseEndpointByType('ExportProjectLabelToSource'), 
                    {
                        projectId: projectId,
                        projectName: projectName,
                        assetName: assetName
                    });
                if (result && result.data && result.data.successful) {
                    const postData = JSON.parse(result.data.stringValue1);
                    if (postData) {
                        const postUrl = postData.PostUrl;
                        if (postUrl) {
                            this.postToUrl(result.data.postUrl, result.data.authorizationHeader, result.data.base64Content);
                            resolve(true);
                        }
                    }                    
                }        
                else {
                    reject(result.data.stringValue1);
                }        
            }
            catch(ex) {
                reject(ex);
            }
        })
    }

    private base64ToArrayBuffer(base64) {
        var binaryString = atob(base64);
        var bytes = new Uint8Array(binaryString.length);
        for (var i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes.buffer;
    }

    private postToUrl(url, token, stringArr) {
        var base64String = btoa(stringArr);
        var byteArrFromStr = this.base64ToArrayBuffer(stringArr);
        axios.post(url, byteArrFromStr, {
            withCredentials: false,
            headers: {
                'Content-Type': 'application/json', 
                'Authorization': token
            }
            }).then(response => {
                if (response.data) {
                    window.location.href = response.data;
                }                
            }).catch(error => {
                console.error(error);
            });
        
    }
}

