import { DatePicker, DayOfWeek,  DefaultButton,  Dropdown, FontIcon, IContextualMenuProps, IDatePickerStrings, IDropdownOption, IDropdownStyles, IStackTokens, IconButton, Pivot, PivotItem, PrimaryButton, Stack, TextField, mergeStyleSets } from "@fluentui/react";
import { Label } from 'office-ui-fabric-react/lib/Label';
import { DetailsList, DetailsListLayoutMode, Selection, SelectionMode, IColumn } from '@fluentui/react/lib/DetailsList';
import React, { SyntheticEvent } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { IApplicationState } from "vott-react";
import IClientManagementActions, * as cmActions from "../../../../redux/actions/clientManagementActions";
import { IFilterOption, IGetDocumentServiceModel } from "../../../../models/applicationState";
import moment from "moment";
import { strings } from "../../../../common/strings";

interface IClientManagementPageProps extends RouteComponentProps, React.Props<ClientManagementPage> {

}

interface IClientManagementPageState {
    firstDayOfWeek?: DayOfWeek;
    documents: IGetDocumentServiceModel[];
    minDate?: Date;
    startDate?: Date;
    endDate?: Date;    
    filterOptions?: IFilterOption[]
    processor?: string;
    application?: string;
    domain?: string;
}

const controlStyles = {
    root: {
        margin: '0 30px 20px 0',
        maxWidth: '300px',
    },
};

const DayPickerStrings: IDatePickerStrings = {
    months: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
  
    shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  
    days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  
    shortDays: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
  
    goToToday: 'Go to today',
    prevMonthAriaLabel: 'Go to previous month',
    nextMonthAriaLabel: 'Go to next month',
    prevYearAriaLabel: 'Go to previous year',
    nextYearAriaLabel: 'Go to next year',
    closeButtonAriaLabel: 'Close date picker',
    //monthPickerHeaderAriaLabel: '{0}, select to change the year',
    //yearPickerHeaderAriaLabel: '{0}, select to change the month',
};
const controlClass = mergeStyleSets({
    control: {
      margin: '0 0 15px 0',
      maxWidth: '300px',
    },
});

const numericalSpacingStackTokens: IStackTokens = {
    childrenGap: 10,
    padding: 10,
};

const dropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: 300 } };

export default class ClientManagementPage extends React.Component<IClientManagementPageProps, IClientManagementPageState> {
    constructor(props) {
        super(props);

        this.state = {
            processor: '',
            firstDayOfWeek: DayOfWeek.Sunday,
            documents: []
            
        }

        this.onSelectedProcessorChange = this.onSelectedProcessorChange.bind(this);     
        this.onSelectedApplicationChange = this.onSelectedApplicationChange.bind(this);
        this.onSelectedDomainChange = this.onSelectedDomainChange.bind(this);
    }

    componentDidMount(): void {
        cmActions.getIntegrationFilterOptions()
            .then(result => {
                this.setState({
                    filterOptions: result
                });
            });

        document.title = "Client management - " + strings.appName;
    }

    startDate: Date;
    endDate: Date;

    private onProcessorChange = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string): void => {
        this.setState({
            processor: text
        });
    };

    columns: IColumn[] = [
        {
            key: 'documentServiceId',
            name: 'Document Service Id',
            fieldName: 'documentServiceId',
            minWidth: 210,
            maxWidth: 350,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            onRender: (item: IGetDocumentServiceModel) => {
                return <span>{item.documentServiceId}</span>;
            },
            isPadded: true,
        },
        {
            key: 'rollId',
            name: 'Roll Id',
            fieldName: 'rollId',
            minWidth: 70,
            maxWidth: 90,
            isResizable: true,
            data: 'string',
            onRender: (item: IGetDocumentServiceModel) => {
                return <span>{item.rollId}</span>;
            },
            isPadded: true,
        },
        {
            key: 'clientId',
            name: 'Client Id',
            fieldName: 'clientId',
            minWidth: 70,
            maxWidth: 90,
            isResizable: true,
            isCollapsible: true,
            onRender: (item: IGetDocumentServiceModel) => {
                return <span>{item.clientId}</span>;
            },
            isPadded: true,
        },
        {
            key: 'pages',
            name: 'Pages',
            fieldName: 'pages',
            minWidth: 70,
            maxWidth: 90,
            isResizable: true,
            isCollapsible: true,
            onRender: (item: IGetDocumentServiceModel) => {
                return <span>{item.pages}</span>;
            },
            isPadded: true,
        },
        {
            key: 'processor',
            name: 'Processor',
            fieldName: 'processor',
            minWidth: 70,
            maxWidth: 90,
            isResizable: true,
            isCollapsible: true,
            onRender: (item: IGetDocumentServiceModel) => {
                return <span>{item.processor}</span>;
            },
            isPadded: true,
        },
        {
            key: 'dateCreated',
            name: 'Date Created',
            fieldName: 'dateCreated',
            minWidth: 70,
            maxWidth: 90,
            isResizable: true,
            isCollapsible: true,
            onRender: (item: IGetDocumentServiceModel) => {
                return <span>{item.dateCreated}</span>;
            },
            isPadded: true,
        }
    ];

    private onSelectStartDate = (date: Date | null | undefined) => {
        //const startDate = date.getTime();
        this.startDate = date;
        this.setState({
            minDate: date
        });
    }

    private onSelectEndDate = (date: Date | null | undefined) => {
        this.endDate = date;
    }

    private onSearch = () => {
        let startTime = null;
        if (this.startDate) {
            this.startDate.setHours(0, 0, 0, 0);
            startTime = this.startDate.getTime();
        }
        let endTime = null;
        if (this.endDate) {
            this.endDate.setHours(23, 59, 59, 999);
            endTime = this.endDate.getTime();
        }
        cmActions.getDocumentServices(this.state.application, this.state.domain, this.state.processor, startTime, endTime)
            .then(documents => {
                this.setState({
                    documents: documents
                });
            });
    }

    private onSelectedProcessorChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
        if (item.key === 'all') {
            this.setState({
                processor: null
            });
        }
        else {
            this.setState({
                processor: item.key as string
            });
        }        
    };

    private onSelectedApplicationChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
        if (item.key === 'all') {
            this.setState({
                application: null
            });
        }
        else {
            this.setState({
                application: item.key as string
            });
        }        
    };

    private onSelectedDomainChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
        if (item.key === 'all') {
            this.setState({
                domain: null
            });
        }
        else {
            this.setState({
                domain: item.key as string
            });
        }        
    };

    exportTableToExcel(tableId) {
        const table = document.getElementById(tableId);
        const rows = table.querySelectorAll('tr');
        let csvContent = '';

        // Extracting table headers
        const headers = [];
        const headerRow = rows[0].querySelectorAll('th');
        headerRow.forEach(function(header) {
            headers.push(header.innerText);
        });
        csvContent += headers.join(',') + '\n';

        // Extracting table rows data
        rows.forEach(function(row, index) {
            if (index !== 0) {
                const rowData = [];
                row.querySelectorAll('td').forEach(function(cell) {
                    rowData.push(cell.innerText);
                });
                csvContent += rowData.join(',') + '\n';
            }
        });

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', 'document-service.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    render() {
        const { firstDayOfWeek, documents, minDate, startDate, endDate, filterOptions, application, domain, processor } = this.state;
        let applications = [];
        let domains = [];
        let processors = [];
        if (filterOptions && filterOptions.length > 0) {
            applications = filterOptions.filter(r => r.filterType === 'Application').map(a => {
                return {
                    key: a.name,
                    text: a.name
                }
            });
            if (application) {
                applications.unshift({
                    key: '',
                    text: '(Select application)'
                });
            }
            
            domains = filterOptions.filter(r => r.filterType === 'Domain').map(a => {
                return {
                    key: a.name,
                    text: a.name
                }
            });
            if (domain) {
                domains.unshift({
                    key: '',
                    text: '(Select domain)'
                });
            }
            
            processors = filterOptions.filter(r => r.filterType === 'Processors').map(a => {
                return {
                    key: a.name,
                    text: a.name
                }
            });
            if (processor) {
                processors.unshift({
                    key: '',
                    text: '(Select processor)'
                });
            }            
        }        

        const processorOptions = []

        const sum = documents.reduce((accumulator: number, object: IGetDocumentServiceModel) => {
            return accumulator + parseInt(object.pages);
          }, 0);

        return (
            <React.Fragment>            
            <div className="uploadEndpoints-page">
                <Stack>
                    <Stack horizontal horizontalAlign="start" verticalAlign="start" verticalFill wrap>
                        <h3>
                            Client Management
                        </h3>
                    </Stack>
                    <Pivot aria-label="Basic Pivot Example">
                        <PivotItem
                            headerText="Integration Metrics"
                            headerButtonProps={{
                            'data-order': 1,
                            'data-title': 'My Files Title',
                            }}
                        >
                            <Stack horizontal horizontalAlign="start" verticalAlign="start" verticalFill wrap tokens={numericalSpacingStackTokens}>
                                <Stack.Item>
                                    <Dropdown
                                        placeholder="(Select application)"
                                        onChange={this.onSelectedApplicationChange}
                                        options={applications}
                                        styles={dropdownStyles}
                                        selectedKey={application}
                                    />
                                </Stack.Item>
                                <Stack.Item>
                                    <Dropdown
                                        placeholder="(Select domain)"
                                        onChange={this.onSelectedDomainChange}
                                        options={domains}
                                        styles={dropdownStyles}
                                        selectedKey={domain}
                                    />
                                </Stack.Item>
                                <Stack.Item>
                                    <Dropdown
                                        placeholder="(Select processor)"
                                        onChange={this.onSelectedProcessorChange}
                                        options={processors}
                                        styles={dropdownStyles}
                                        selectedKey={processor}
                                    />
                                </Stack.Item>
                                <Stack.Item>
                                    <DatePicker
                                        key={'startDate'}
                                        className={controlClass.control}
                                        firstDayOfWeek={firstDayOfWeek}
                                        strings={DayPickerStrings}
                                        placeholder="Select a start date..."
                                        ariaLabel="Select a start date"
                                        onSelectDate={this.onSelectStartDate}
                                        value={this.startDate}
                                    />    
                                </Stack.Item>
                                <Stack.Item>
                                    <DatePicker
                                        key={'endDate'}
                                        className={controlClass.control}
                                        firstDayOfWeek={firstDayOfWeek}
                                        strings={DayPickerStrings}
                                        placeholder="Select an end date..."
                                        ariaLabel="Select an end date"
                                        onSelectDate={this.onSelectEndDate}
                                        minDate={minDate}
                                        value={this.endDate}
                                    />    
                                </Stack.Item>
                                <Stack.Item>
                                    <PrimaryButton ariaDescription="Post to Optimus." onClick={this.onSearch} className="save-to-optimus" >
                                        <FontIcon 
                                                iconName='Search'                                         
                                                title="Search"                                
                                        />                        
                                        &nbsp;
                                        Search
                                    </PrimaryButton>        
                                    <DefaultButton
                                        text="Export XLSX"
                                        onClick={() => this.exportTableToExcel('documentServiceTable')}
                                        disabled={!documents || documents.length < 1}
                                        // disabled={disabled}
                                        // checked={checked}
                                    />                    
                                </Stack.Item>                        
                            </Stack>               
                            <Stack>
                                <table className="table" id='documentServiceTable'>
                                    <thead>
                                        <tr>
                                            <th>
                                                Document Service Id
                                            </th>
                                            <th>
                                                Roll Id
                                            </th>
                                            <th>
                                                Client Id
                                            </th>
                                            <th>
                                                OCR'd Pages
                                            </th>
                                            <th>
                                                Processor
                                            </th>
                                            <th>
                                                Date Processed
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { documents && documents.length > 0 && 
                                            documents.map(document => {
                                                // Parse the date with moment
                                                const dateCreatedUtc = moment.utc(document.dateCreated);
                                                const dateCreated = dateCreatedUtc.local();
                                                const formattedDate = dateCreated.format('MM/DD/YYYY HH:mm:ss');
                                                return (
                                                    <tr>
                                                        <td>
                                                            { document.documentServiceId }
                                                        </td>
                                                        <td>
                                                            { document.rollId }
                                                        </td>
                                                        <td>
                                                            { document.clientId }
                                                        </td>
                                                        <td style={{textAlign: 'center'}}>
                                                            { document.pages }
                                                        </td>
                                                        <td>
                                                            { document.processor }
                                                        </td>
                                                        <td>
                                                            { formattedDate }
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td>Total:</td>
                                            <td style={{textAlign: 'center'}}>{sum}</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Stack>     
                        </PivotItem>
                        {/* <PivotItem headerText="Stand-alone Metrics">
                            <Label>To Follow...</Label>
                        </PivotItem>
                        <PivotItem headerText="User Management">
                            <Label>To Follow...</Label>
                        </PivotItem> */}
                    </Pivot>                                        
                </Stack>
            </div>
            </React.Fragment>
        )
    }
}