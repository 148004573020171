import axios from "axios";
import { ICompany, IFilterOption, IGetCompaniesResult, IGetDocumentServiceModel } from "../models/applicationState";
import { parseEndpointByType } from "../react/components/common/jsonSchemaFormHelper";


export default class ClientManagementService {
    
    public async getCompanies() {
        return new Promise<ICompany[]>(async (resolve, reject) => {
            try {
                const getResult = await axios.get(parseEndpointByType('GetCompanies'));
                if (getResult && getResult.data?.successful ) { 
                    resolve(getResult.data.companies);
                }
            }
            catch(ex) {
                reject(ex);
            }
        })
    }

    public async getDocumentServices(application?: string, domain?:string, processor?: string, startDate?: number, endDate?: number) {
        return new Promise<IGetDocumentServiceModel[]>((resolve, reject) => {
            axios.post(parseEndpointByType('GetDocumentServices'), {
                application, 
                domain,
                processor, 
                startDate,
                endDate
            })
            .then(getResult => {
                if (getResult && getResult.data?.successful ) { 
                    resolve(getResult.data.getDocumentServiceModels);
                }
            })
            .catch(ex => {
                reject(ex);
            });
        });
    }

    public async getIntegrationFilterOptions() {
        return new Promise<IFilterOption[]>((resolve, reject) => {
            axios.get(parseEndpointByType('GetIntegrationFilterOptions'))
            .then(getResult => {
                if (getResult && getResult.data?.successful ) { 
                    //const filterOptions = JSON.parse<IFilterOption, >(getResult.data?.stringValue1);
                    resolve(getResult.data.filterOptions);
                }
            })
            .catch(ex => {
                reject(ex);
            });
        });
    }    
}
