import axios from "axios";
import React from "react";

import {
    getDarkTheme,
    getPrimaryGreenTheme,
    getPrimaryGreyTheme,
} from "../../../../common/themes";

import {
    Customizer,
    Dialog,
    DialogContent,
    DialogType,
    ICustomizations,
    Label,
    PrimaryButton,
    Spinner,
    SpinnerSize,
} from "@fluentui/react";
import Success from "../success/success";

import { IFormResetPassword, IFormResetPasswordCommand } from '../../../../models/applicationState';
import { addLocValues } from "../../../../common/strings";
import Form, { FormValidation, ISubmitEvent } from "react-jsonschema-form";
import CustomFieldTemplate from "../customField/customFieldTemplate";
import { baseUrl, parseEndpointByType } from "../jsonSchemaFormHelper";

export interface IResetPasswordDialogProps {
    backClick: () => void;
    data?: IFormResetPassword,
    onLoginClick: () => void;
    showResetPasswordDialog: boolean;
    onSuccessRegistrationClick: () => void;
}

export interface IResetPasswordDialogState {
    email: string;
    showSuccess: boolean;
    resetPasswordFormSchema: any;
    resetPasswordUIFormSchema: any;
    formData: IFormResetPassword;
    show: boolean;
    loading: boolean;
    saving: boolean;
    errors: FormValidation;
}

const resetPasswordFormSchema = addLocValues(
    require("./resetPasswordDialog.json")
);
const resetPasswordUIFormSchema = addLocValues(
    require("./resetPasswordDialog.ui.json")
);

export default class ResetPasswordDialog extends React.Component<IResetPasswordDialogProps, IResetPasswordDialogState> {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            email: '',
            showSuccess: false,
            resetPasswordFormSchema: {},
            resetPasswordUIFormSchema: {},
            formData: {} as IFormResetPassword,
            loading: false,
            saving: false,
            errors: {} as FormValidation
        }

        this.onCloseSuccessClick = this.onCloseSuccessClick.bind(this);
        this.showLoginClick = this.showLoginClick.bind(this);
        this.onCloseClick = this.onCloseClick.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
    }

    componentDidMount() {
        this.setState({
            resetPasswordFormSchema
        });
        this.setState({
            resetPasswordUIFormSchema,
            formData: this.props.data
        })
    }

    private onCloseSuccessClick() {
        this.setState({
            showSuccess: false,
        });
    }

    private showLoginClick() {
        this.props.onLoginClick();
    }

    private onCloseClick() {
        this.setState({
            show: false,
        });
    }

    private onFormValidate = (
        resetPassword: IFormResetPassword,
        errors: FormValidation
    ) => {
        return errors;
    };

    private onFormChange() {
        
    }

    private onFormSubmit(args: ISubmitEvent<IFormResetPassword>) {
        try {
            this.setState({
                saving: true,
                formData: args.formData,
            }, () => {
                axios
                    .post(parseEndpointByType("ResetPassword"), {
                        email: args.formData.email
                    } as IFormResetPassword)
                    .then((response: any) => {
                        if (response.data.successful) {
                            this.setState({
                                showSuccess: true,
                            });

                            this.props.onSuccessRegistrationClick();

                            //this.props.backClick();
                        }
                    })
                    .catch((error) => console.log(error))
                    .finally(() => {
                        this.setState({
                            saving: false,
                        });
                    });
            });
        } catch (error) {
            console.log(error);
        }
    }

    private fields() {
        return {};
    }


    public render() {
        const dark: ICustomizations = {
            settings: {
                theme: getDarkTheme(),
            },
            scopedSettings: {},
        };

        return (
            <React.Fragment>
                <Success
                    title="Reset Password"
                    message="Instructions on how to reset your password has been sent to your email account."
                    show={this.state.showSuccess}
                    onClose={this.onCloseSuccessClick}
                    showClose={false}
                />

                <Dialog
                    // onDismiss={this.onCloseClick}
                    hidden={!this.props.showResetPasswordDialog}
                    dialogContentProps={{
                        type: DialogType.normal,

                        isMultiline: true,
                    }}
                    minWidth="700px"
                    modalProps={{
                        isBlocking: false,
                    }}
                >
                    <DialogContent>
                        {this.state.loading && (
                            <div className="project-saving small">
                                <div className="project-saving-spinner">
                                    <Label className="p-0"></Label>
                                    <Spinner
                                        size={SpinnerSize.large}
                                        label="Loading..."
                                        ariaLive="assertive"
                                        labelPosition="right"
                                    />
                                </div>
                            </div>
                        )}
                        <div className="row">
                            <div className="col-sm-12">
                                <h3
                                    style={{
                                        marginTop: "-40px",
                                        marginBottom: "30px",
                                    }}
                                >
                                    Enter your email to reset your hatchet password
                                </h3>
                            </div>
                        </div>
                        <Form
                            showErrorList={false}
                            liveValidate={true}
                            noHtml5Validate={true}
                            FieldTemplate={CustomFieldTemplate}
                            validate={this.onFormValidate}
                            schema={this.state.resetPasswordFormSchema}
                            uiSchema={this.state.resetPasswordUIFormSchema}
                            formData={this.state.formData}
                            onChange={this.onFormChange}
                            onSubmit={this.onFormSubmit}
                            fields={this.fields()} autocomplete="false"
                        >
                            <div className="row">
                                <div className="col-sm-6">
                                    <PrimaryButton
                                        disabled={
                                            this.state.errors.__errors &&
                                            this.state.errors.__errors.length >
                                            0
                                        }
                                        theme={getPrimaryGreenTheme()}
                                        className="mr-2"
                                        type="submit"
                                    >
                                        {this.state.saving ? (
                                            <Spinner
                                                size={SpinnerSize.small}
                                                label="Saving..."
                                                ariaLive="off"
                                                labelPosition="right"
                                            />
                                        ) : (
                                            "Reset Password"
                                        )}
                                    </PrimaryButton>
                                    <PrimaryButton
                                        disabled={
                                            this.state.errors.__errors &&
                                            this.state.errors.__errors.length >
                                            0
                                        }
                                        theme={getPrimaryGreyTheme()}
                                        className="mr-2"
                                        type="button"
                                        onClick={this.props.backClick}
                                    >
                                        Back
                                    </PrimaryButton>
                                </div>
                            </div>
                        </Form>
                    </DialogContent>
                </Dialog>
            </React.Fragment>
        )
    }
}
