import React from "react";
import axios from "axios";

import {
    getDarkTheme,
    getPrimaryGreenTheme,
    getPrimaryGreyTheme,
} from "../../../../common/themes";
import { addLocValues } from "../../../../common/strings";
import { IFormChangePassword } from "../../../../models/applicationState";
import { Dialog, DialogContent, DialogType, ICustomizations, Label, PrimaryButton, Spinner, SpinnerSize } from "@fluentui/react";
import Success from "../success/success";
import Form, { FormValidation, ISubmitEvent } from "react-jsonschema-form";
import CustomFieldTemplate from "../customField/customFieldTemplate";
import { parseEndpointByType } from "../jsonSchemaFormHelper";

export interface IChangePasswordDialogProps {
    data?: IFormChangePassword;
    showChangePasswordDialog: boolean;
    onSuccessChangePasswordClick: () => void;
    backClick: () => void;
    userName: string;
    userKey: string;
}

export interface IChangePasswordDialogState {
    changePasswordFormSchema: any;
    changePasswordUIFormSchema: any;
    formData: IFormChangePassword;
    showSuccess: boolean;
    loading: boolean;
    saving: boolean;
    errors: FormValidation;
}

const changePasswordFormSchema = addLocValues(
    require("./changePasswordDialogForm.json")
);

const changePasswordUIFormSchema = addLocValues(
    require("./changePasswordDialogForm.ui.json")
);

export default class ChangePasswordDialog extends React.Component<IChangePasswordDialogProps, IChangePasswordDialogState> {
    constructor(props) {
        super(props);

        this.state = {
            showSuccess: false,
            changePasswordFormSchema: {},
            changePasswordUIFormSchema: {},
            formData: {} as IFormChangePassword,
            loading: false,
            saving: false,
            errors: {} as FormValidation
        }

        this.onCloseSuccessClick = this.onCloseSuccessClick.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
    }

    componentDidMount() {
        this.setState({
            changePasswordFormSchema
        });
        this.setState({
            changePasswordUIFormSchema,
            formData: this.props.data
        });
    }

    private onCloseSuccessClick() {
        this.setState({
            showSuccess: false,
        });
    }

    private onFormValidate = (
        resetPassword: IFormChangePassword,
        errors: FormValidation
    ) => {
        if (resetPassword.password !== resetPassword.confirmPassword) {
            errors.addError("Password must match");
        }
        return errors;
    };

    private onFormChange() {
        
    }

    private onFormSubmit(args: ISubmitEvent<IFormChangePassword>) {        
        try {
            const self = this;
            this.setState({
                saving: true,
                formData: args.formData,
            }, () => {
                axios
                    .post(parseEndpointByType("ChangePassword"), {
                        resetKey: self.props.userKey,
                        newPassword: args.formData.password
                    })
                    .then((response: any) => {
                        if (response.data.successful) {
                            this.setState({
                                showSuccess: true,
                            }, () => {
                                setTimeout(function () {
                                    window.location.href = '/';
                                }, 3000);
                            });                            
                        }
                    })
                    .catch((error) => console.log(error))
                    .finally(() => {
                        this.setState({
                            saving: false,
                        });
                    });
            });
        } catch (error) {
            console.log(error);
        }
    }

    private fields() {
        return {};
    }

    public render() {
        const dark: ICustomizations = {
            settings: {
                theme: getDarkTheme(),
            },
            scopedSettings: {},
        };
        return (
            <React.Fragment>
                <Success
                    title="Password Successfully Changed"
                    message="You will be redirected to login page, you can now then use your new credentials."
                    show={this.state.showSuccess}
                    onClose={this.onCloseSuccessClick}
                    showClose={false}
                />

                <Dialog
                    // onDismiss={this.onCloseClick}
                    hidden={!this.props.showChangePasswordDialog}
                    dialogContentProps={{
                        type: DialogType.normal,

                        isMultiline: true,
                    }}
                    minWidth="700px"
                    modalProps={{
                        isBlocking: false,
                    }}
                >
                    <DialogContent>
                        {this.state.loading && (
                            <div className="project-saving small">
                                <div className="project-saving-spinner">
                                    <Label className="p-0"></Label>
                                    <Spinner
                                        size={SpinnerSize.large}
                                        label="Loading..."
                                        ariaLive="assertive"
                                        labelPosition="right"
                                    />
                                </div>
                            </div>
                        )}
                        <div className="row">
                            <div className="col-sm-12">
                                <h3
                                    style={{
                                        marginTop: "-40px",
                                        marginBottom: "30px",
                                    }}
                                >
                                    Welcome back {this.props.userName}! Enter your new password
                                </h3>
                            </div>
                        </div>
                        <Form
                            showErrorList={false}
                            liveValidate={true}
                            noHtml5Validate={true}
                            FieldTemplate={CustomFieldTemplate}
                            validate={this.onFormValidate}
                            schema={this.state.changePasswordFormSchema}
                            uiSchema={this.state.changePasswordUIFormSchema}
                            formData={this.state.formData}
                            onChange={this.onFormChange}
                            onSubmit={this.onFormSubmit}
                            fields={this.fields()} autocomplete="false"
                        >
                            <div className="row">
                                <div className="col-sm-6">
                                    <PrimaryButton
                                        disabled={
                                            this.state.errors.__errors &&
                                            this.state.errors.__errors.length >
                                            0
                                        }
                                        theme={getPrimaryGreenTheme()}
                                        className="mr-2"
                                        type="submit"
                                    >
                                        {this.state.saving ? (
                                            <Spinner
                                                size={SpinnerSize.small}
                                                label="Saving..."
                                                ariaLive="off"
                                                labelPosition="right"
                                            />
                                        ) : (
                                            "Reset Password"
                                        )}
                                    </PrimaryButton>
                                    <PrimaryButton
                                        disabled={
                                            this.state.errors.__errors &&
                                            this.state.errors.__errors.length >
                                            0
                                        }
                                        theme={getPrimaryGreyTheme()}
                                        className="mr-2"
                                        type="button"
                                        onClick={this.props.backClick}
                                    >
                                        Back
                                    </PrimaryButton>
                                </div>
                            </div>
                        </Form>
                    </DialogContent>
                </Dialog>
            </React.Fragment>
        )
    }
}
